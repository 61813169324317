/* From Uiverse.io by waliddu001 */

.backtoTop {
    width: 150px;
    height: 50px;
    /* border: 1px solid rgb(255, 0, 0); */
    position: fixed;
    bottom: 10px;
    right: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}
.button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(51, 51, 51);
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    /* box-shadow: 0px 0px 0px 4px rgba(180, 160, 255, 0.253); */
    cursor: pointer;
    transition-duration: 0.3s;
    overflow: hidden;
}

.svgIcon {
    width: 12px;
    transition-duration: 0.3s;
}

.svgIcon path {
    fill: white;
}

.button:hover {
    width: 140px;
    border-radius: 50px;
    transition-duration: 0.3s;
    background-color: rgb(255, 255, 255);
    align-items: center;
}

.button:hover .svgIcon {
    /* width: 20px; */
    transition-duration: 0.3s;
    transform: translateY(-200%);
}

.button::before {
    position: absolute;
    bottom: -20px;
    content: "Back to Top";
    color: rgb(0, 0, 0);
    /* transition-duration: .3s; */
    font-size: 0px;
}

.button:hover::before {
    font-size: 13px;
    opacity: 1;
    bottom: unset;
    /* transform: translateY(-30px); */
    transition-duration: 0.3s;
}
@media screen and (max-width: 768px) {
    .backtoTop {
        right: 40%;
    }
}
@media screen and (max-width: 426px) {
   .backtoTop {
        right: 30%;
    }
}
@media screen and (max-width: 376px) {
   .backtoTop {
        right: 28%;
    }
}
@media screen and (max-width: 321px) {
   .backtoTop {
        right: 26%;
    }
}

