.main {
    /* border: 1px solid white; */
    display: flex;
    width: 100%;
    height: 80vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2%;
}

.buttons {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 90px;
}

.Voice-btn {
    height: 10vh;
    width: 40%;
}
.button4 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 8.7em;
    height: 8.7em;
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: #515254;
    box-shadow: rgba(0, 0, 0, 0.377) 10px 10px 8px,
        #a8a6a4 1.5px 1.5px 1px 0px inset, #545251 -3.2px -3.2px 8px 0px inset;
    cursor: pointer;
    font-family: Montserrat;
    transition: 0.1s ease-in-out;
}
.toggle-button-cont {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* From Uiverse.io by adamgiebl */
.toggle-button {
    width: 100%;
    font-size: 15px;
    display: inline-block;
    outline: 0;
    border: 0;
    cursor: pointer;
    will-change: box-shadow, transform;
    background: radial-gradient(100% 100% at 100% 0%, #89e5ff 0%, #5468ff 100%);
    box-shadow: 0px 0.01em 0.01em rgb(45 35 66 / 40%),
        0px 0.3em 0.7em -0.01em rgb(45 35 66 / 30%),
        inset 0px -0.01em 0px rgb(58 65 111 / 50%);
    padding: 0 2em;
    border-radius: 0.3em;
    color: #fff;
    height: 2.6em;
    text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
    transition: box-shadow 0.15s ease, transform 0.15s ease;
}

.toggle-button:hover {
    box-shadow: 0px 0.1em 0.2em rgb(45 35 66 / 40%),
        0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.1em 0px #3c4fe0;
    transform: translateY(-0.1em);
}

.toggle-button:active {
    box-shadow: inset 0px 0.1em 0.6em #3c4fe0;
    transform: translateY(0em);
}

.text {
    color: white;
    height: 4vh;
    width: 100%;
    font-family: Montserrat;
    text-align: center;
    font-size: 1rem;
}

.button4:active {
    box-shadow: rgba(0, 0, 0, 0.377) 0px 0px 0px, inset 0.5px 0.5px 4px #000000,
        #545251 -3.2px -3.2px 8px 0px inset;
}
.button4:active .button_text {
    transform: translateY(0.5px);
}

.voicePlaypause {
    width: 100%;
    height: 7vh;
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: #545251;
    box-shadow: rgba(0, 0, 0, 0.377) 10px 10px 8px,
        #a8a6a4 1.5px 1.5px 1px 0px inset, #545251 -3.2px -3.2px 8px 0px inset;
    cursor: pointer;
}

.voicePlaypause:active {
    box-shadow: rgba(0, 0, 0, 0.377) 0px 0px 0px, inset 0.5px 0.5px 4px #000000,
        #545251 -3.2px -3.2px 8px 0px inset;
}
.voice-button-image {
    height: 8.7em;
    width: 8.7em;
}
.voice-name {
    color: white;
    font-size: 1rem;
    text-align: center;
}
.video-popup {
    position: fixed;
    top: 20px; /* Position from top */
    right: 20px; /* Position from right */
    width: 300px; /* Set width of the popup */
    background: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    border-radius: 10px; /* Rounded corners */
    z-index: 1000; /* Ensure it’s on top */
}

.video-popup-content {
    position: relative;
    padding: 10px;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    font-size: 20px;
    cursor: pointer;
}

.video-button-image {
    width: 50px; /* Adjust size */
    height: auto; /* Maintain aspect ratio */
}
