.story1,
.story2,
.story3,
.story4,
.story5,
.story6 {
    background: radial-gradient(#ffaae8, #c498ff);
    position: relative;
    border-radius: 10px;
    width: 100%;
    height: 41vh;
    -webkit-box-shadow: 1px 1px 12px #000;
    box-shadow: 1px 1px 12px #000;
    -webkit-transform: preserve-3d;
    -ms-transform: preserve-3d;
    transform: preserve-3d;
    -webkit-perspective: 2000px;
    perspective: 2000px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #000;
    /* margin-left: -20%; */
}

.cover {
    top: 0;
    position: absolute;
    width: 100%;
    height: 41vh;
    border-radius: 10px;
    cursor: pointer;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transform-origin: 0;
    -ms-transform-origin: 0;
    transform-origin: 0;
    -webkit-box-shadow: 1px 1px 12px #000;
    box-shadow: 1px 1px 12px #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.story1:hover .cover,
.story2:hover .cover,
.story3:hover .cover,
.story4:hover .cover,
.story5:hover .cover,
.story6:hover .cover {
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transform: rotatey(-80deg);
    -ms-transform: rotatey(-80deg);
    transform: rotatey(-80deg);
}
.cover > img {
    width: 100%;
    height: 41vh;
    border-radius: 10px;
}
.homeTitle > p {
    font-family: "Arvo";
    color: black;
    font-size: 23px;
}

.outerline {
    border: 5px solid rgb(44, 61, 88);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
        rgba(0, 0, 0, 0.23) 0px 6px 6px, inset rgba(0, 0, 0, 0.19) 0px 10px 20px,
        inset rgba(0, 0, 0, 0.23) 0px 6px 6px;
    margin-left: 10%;
    height: 35vh;
    width: 80%;
    border-radius: 8px;
    transition: all ease-in-out 0.8s;
}
.outerline:hover {
    box-shadow: rgba(193, 44, 223, 0.19) 0px 10px 20px,
        rgba(193, 44, 223, 0.23) 0px 6px 6px,
        inset rgba(0, 0, 0, 0.19) 0px 10px 20px,
        inset rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border: 5px solid rgb(83, 148, 253);
}
.homecardTop {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.hometopImg > img {
    margin-top: 10%;
    margin-left: 10%;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    width: 80%;
    height: 20vh;
}
.homeCardBtn {
    margin: 5% auto;
}
.homeBooksBtn {
    cursor: pointer;
    font-weight: 700;
    font-family: Helvetica, "sans-serif";
    transition: all 0.2s;
    padding: 3% 9%;
    border-radius: 100px;
    background: #0091ff;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    font-size: 13px;
}

.homeBooksBtn:hover {
    background: #8fceff;
}

.homeBooksBtn > svg {
    height: 3vh;
    margin-left: 8%;
    transition: transform 0.3s ease-in-out;
}

.homeBooksBtn:hover svg {
    transform: translateX(8px);
}

.homeBooksBtn:active {
    transform: scale(0.95);
}

.homebooks {
    height: 50vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 15%;
}
