.ripple-background {
    position: fixed; /* Fix it in place */
    top: 2;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: -1; /* Ensure it's behind all other content */
}
.right-ripple-background {
    position: fixed; /* Fix it in place */
    top: 0;
    right: -80%;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: -1; /* Ensure it's behind all other content */
}

.circle {
    position: absolute;
    border-radius: 50%;
    background: #626262a8;
    animation: ripple 15s infinite;
}

/* Circle Sizes */
.small {
    width: 200px;
    height: 200px;
    left: -100px;
    bottom: -100px;
}


.medium {
    width: 400px;
    height: 400px;
    left: -200px;
    bottom: -200px;
}


.large {
    width: 600px;
    height: 600px;
    left: -300px;
    bottom: -300px;
}

.xlarge {
    width: 800px;
    height: 800px;
    left: -400px;
    bottom: -400px;
}

.xxlarge {
    width: 1000px;
    height: 1000px;
    left: -500px;
    bottom: -500px;
}

/* Opacity Levels */
.shade1 {
    opacity: 0.2;
}
.shade2 {
    opacity: 0.5;
}
.shade3 {
    opacity: 0.7;
}
.shade4 {
    opacity: 0.8;
}
.shade5 {
    opacity: 0.9;
}

/* Ripple Animation */
@keyframes ripple {
    0% {
        transform: scale(0.8);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(0.8);
    }
}
