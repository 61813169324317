.ResetPas-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: transparent;
    font-family: 'Arial', sans-serif;
}

.Resetpas {
    background-color: #fff;
    padding: 40px 30px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    box-sizing: border-box;
}

.Resetpas-form h2 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.Resetpas-form div {
    margin-bottom: 15px;
}

.Resetpas-form input {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    transition: border 0.3s ease;
}

.Resetpas-form input:focus {
    border-color: #4CAF50;
    box-shadow: 0 0 8px rgba(76, 175, 80, 0.2);
}

.Resetpas-form button {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.Resetpas-form button:hover {
    background-color: #45a049;
}

.Resetpas-form button:active {
    background-color: #388e3c;
}
