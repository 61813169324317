.books {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    width: 100%;
    height: auto;
    align-items: center;
    display: grid;
    justify-content: center;
    align-items: center;
}
.Books-cover > img {
    height: 34vh;
    width: 100%;
    border-radius: 10px;
}

.bookpage1 {
    margin: 15% auto;
    position: relative;
    border-radius: 10px;
    width: 50%;
    height: 34vh;
    background-color: whitesmoke;
    -webkit-box-shadow: 1px 1px 12px #000;
    box-shadow: 1px 1px 12px #000;
    -webkit-transform: preserve-3d;
    -ms-transform: preserve-3d;
    transform: preserve-3d;
    -webkit-perspective: 2000px;
    perspective: 600px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #000;
    transition-duration: 0.5s;
}

.Books-cover,
.inner {
    top: 0;
    position: absolute;
    background-color: lightgray;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    cursor: pointer;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transform-origin: 0;
    -ms-transform-origin: 0;
    transform-origin: 0;
    -webkit-box-shadow: 1px 1px 12px #000;
    box-shadow: 1px 1px 12px #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.bookpage1:hover .Books-cover {
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transform: rotatey(70deg);
    -ms-transform: rotatey(70deg);
    transform: rotatey(-65deg);
}

.inner {
    transform-origin: 100%;
}
.bookpage1:hover .inner {
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transform: rotateZ(25deg) rotateX(-30deg) rotateY(-10deg)
        translateX(140px);
    -ms-transform: rotateZ(25deg) rotateX(-30deg) rotateY(-10deg)
        translateX(140px);
    transform: rotateZ(25deg) rotateX(0deg) rotateY(-10deg) translateX(80px);
    -webkit-box-shadow: 1px 1px 20px #000a;
    box-shadow: 1px 1px 20px #000a;
}

.bookpage1:hover {
    transform: rotateZ(-10deg);
}
.text {
    font-size: 20px;
    font-weight: 100;
}
.card {
    position: relative;
    width: 100%;
    height: 34vh;
    background-color: #fff;

    overflow: hidden;
    border-radius: 10px;
}

.top-card {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 65%;
    transition: height 0.3s ease;
    background-color: #fff;
}

.bottom-card {
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    /* border-bottom-right-radius: 20px; */
    height: 35%;
    transition: height 0.3s ease;
    background-color: black;
}

.bottom-card::before {
    position: absolute;
    background-color: transparent;
    bottom: 89px;
    height: 35vh;
    width: 100%;
    transition: bottom 0.3s ease;
    border-bottom-left-radius: 20px;
    /* box-shadow: 0 30px 0 0 #0a3cff; */
}

.card-content {
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.card-title {
    font-weight: 700;
    font-size: 18px;
}
.card-txt {
    font-size: 14px;
}

.card-btn {
    font-size: 13px;
    margin-top: 2%;
    text-decoration: none;
    color: white;
    background-color: transparent;
    border: solid 2px #fff;
    border-radius: 15px;
    padding: 5%;
}

.card:hover {
    /* box-shadow: 0px 2px 2px #0a3bffa1; */
    /* border: solid 1px #0a3cff; */
}

.card:hover .top-card {
    height: 35%;
    transition: height 0.3s ease;
}
.card:hover .bottom-card {
    height: 65%;
    transition: height 0.3s ease;
}
.card:hover .bottom-card::before {
    bottom: 164px;
    transition: bottom 0.3s ease;
}

.card-btn:hover {
    color: black;
    background-color: #fff;
    transition: background-color 0.4s ease;
}
@media only screen and (max-width: 1439px) {
    .card:hover .top-card {
        height: 30%;
    }
    .card:hover .bottom-card {
        height: 75%;
    }
}
@media only screen and (max-width: 768px) {
    .bookpage1 {
        width: 40%;
        height: 30vh;
    }
    .bookpage1:hover{
        margin-left: 15%;
    }
    
    
}
