.booksMain {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.bookpage-top {
    width: 100%;
    height: 60vh;
}
@import url("https://fonts.googleapis.com/css?family=Roboto:700");
@keyframes showTopText {
    0% {
        transform: translate3d(0, 100%, 0);
    }
    40%,
    60% {
        transform: translate3d(0, 50%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}
@keyframes showBottomText {
    0% {
        transform: translate3d(0, -100%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}
.animated-title {
    font-family: Roboto, Arial, sans-serif;
    height: 50%;
    right: 10px;
    position: absolute;
    top: 45%;
    transform: translate(-50%, -50%);
    width: 60%;
}

.animated-title > div {
    height: 50%;
    overflow: hidden;
    position: absolute;
    width: 60%;
}
.animated-title > div div {
    color: #b6b6b6;
    font-size: 3vw;
    padding: 2vmin 0;
    position: absolute;
}
.animated-title > div div span {
    display: block;
}
.animated-title > div.text-top {
    border-bottom: 1vmin solid #000000;
    top: 0;
}
.animated-title > div.text-top div {
    animation: showTopText 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    bottom: 0;
    transform: translate(0, 100%);
}
.animated-title > div.text-top div span:first-child {
    color: #ffffff;
    font-weight: 800;
}
.animated-title > div.text-bottom {
    bottom: 0;
}
.animated-title > div.text-bottom div {
    animation: showBottomText 0.5s;
    animation-delay: 1.75s;
    animation-fill-mode: forwards;
    font-weight: 700;
    top: 0;
    transform: translate(0, -100%);
}
.bookpage-top > img {
    position: absolute;
    width: 40%;
    height: 40vh;
    left: 50%;
    top: 15%;
}

.book-middle {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media only screen and (max-width: 767px) {
    .bookpage-top {
        height: 90vh;
    }
    .bookpage-top > img {
        width: 85%;
        height: 40vh;
        left: 8%;
        top: 13vh;
    }
    .animated-title {
        position: absolute;
        left: 50%;
        top: 75%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .animated-title > div div {
        font-size: 2rem;
    }
    .animated-title > div {
        width: 80%;
    }
    .animated-title > div div span {
        width: 100%;
    }
}

.popup {
    position: fixed;
    top: 8%;
    right: 20px;

    color: #fff;
    padding: 10px 20px;
    border-radius: 2px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    animation: slideInOut 3s ease;
    background-color: #4caf50;
}
.popup > p {
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

@keyframes slideInOut {
    0% {
        opacity: 0;
        transform: translateX(100%);
    } /* Start off-screen to the right */
    10%,
    90% {
        opacity: 1;
        transform: translateX(0);
    } /* Slide into position */
    100% {
        opacity: 0;
        transform: translateX(-100%);
    } /* Slide off-screen to the left */
}
