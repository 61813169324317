
.contact-main {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;

}
.contact-img {
    width: 50%;
}
.emailform {
    width: 50%;
}
.contact-img > img {
    width: 90%;
}

@media screen and (max-width: 767px) {
    .contact-img {
        display: none;
        width: 100%;
    }
    .emailform {
        width: 100%;
    }
    .contact-main {
        padding: 0;
    }
}
