.Home-cont {
    /* position: absolute; */
    width: 100%;
    display: flex;
    flex-direction: column;
}
.Home-Books {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 10px;
    padding: 5% 2% 8% 2%;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
.home_btmtext {
    background-color: rgb(18, 18, 18);
    height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 0 5% 0;
}

.home_btmtoptext > p {
    margin: 6% 0 5% 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(18, 18, 18); /* Dark background for contrast */
    height: 23vh; /* Height of the text section */
    font-weight: 700; /* Bold text for emphasis */
    font-size: 2.5rem; /* Large font size for readability */
    line-height: 1.4; /* Increased line height for better spacing */
    color: #f1f1f1; /* Softer white for a more pleasant contrast */
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;

    border-radius: 8px;
}
.fstTxT,
.secTxT {
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 1.2;
    color: #ffffff;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.World,
.Stories {
    font-weight: 700;
    color: #475aff;
    display: inline-block; /* Ensure it can be animated independently */
}

@media (max-width: 768px) {
    .fstTxT,
    .secTxT,
    .home_btmtoptext > p {
        font-size: 2rem;
    }

    .World,
    .Stories {
        font-size: 2.2rem;
    }
}

.audio-control {
    width: 100%;
    position: fixed;
    top: 83%;
    right: -93%;
    z-index: 100;
}
.imageslider {
    width: 100%;
    height: 50vh;
    border: 1px solid white;
    margin: 10% 0 5% 0;
}


@media screen and (max-width: 768px) {
    .audio-control {
        width: 100%;
        position: fixed;
        top: 80%;
        right: -90%;
        z-index: 100;
    }
}
@media screen and (max-width: 321px) {
    .audio-control {
        width: 100%;
        position: fixed;
        top: 85%;
        right: -80%;
        z-index: 100;
    }
}
@media screen and (max-width: 376px) {
    .audio-control {
        width: 100%;
        position: fixed;
        top: 85%;
        right: -80%;
        z-index: 100;
    }
}
