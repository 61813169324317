* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.flip-book-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
}

.flip-book {
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 78%;
    height: 80vh;
}

.Voice-cont {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Audio-play-pause {
    position: absolute;
    height: 8vh;
    width: 9%;
    border-radius: 30px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    background-color: #0f0092;
    color: #fff;
    padding: 12px 25px;
    display: inline-block;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 3px;
    left: 80.7%;
    top: 80%;
    cursor: pointer;
    transition: all 0.25s ease;
    box-shadow: -5px 6px 20px 0px rgba(26, 26, 26, 0.544);
    z-index: 1000;
}

.Audio-play-pause:hover {
    background-color: #07013d;
    box-shadow: -5px 6px 20px 0px rgba(88, 88, 88, 0.569);
}
.Audio-play-pause:disabled {
    background-color: black;
    color: white; /* Set text color to contrast with the black background */
    cursor: not-allowed;
}

.audio-control-button {
    position: absolute;
    height: 8vh;
    width: 9%;
    border-radius: 30px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    background-color: #0f0092;
    color: #fff;
    padding: 8px 25px;
    display: inline-block;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 3px;
    left: 80.7%;
    top: 70%;
    cursor: pointer;
    transition: all 0.25s ease;
    box-shadow: -5px 6px 20px 0px rgba(26, 26, 26, 0.544);
    z-index: 1000;
     outline: none;
}

.audio-control-button:hover {
    background-color: #07013d;
    box-shadow: -5px 6px 20px 0px rgba(88, 88, 88, 0.569);
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.808);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 99;
}

.popup-content {
    background-color: #212121;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.slide-in {
    transform: translateX(-100%);
    animation: slide-in 0.5s forwards ease-out;
}

.slide-out {
    transform: translateX(0);
    animation: slide-out 0.5s forwards ease-in;
}

@keyframes slide-in {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
}
.close-popup:hover{
    color: rgb(255, 112, 112);
}

.page {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
}

.male,
.female {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

h1 {
    color: white;
    text-shadow: 2px 2px 4px #000000;
    margin-bottom: 50px;
    height: 10px;
    font-family: Arial white;
    font-size: 40px;
}

h2 {
    font-family: "Brush Script MT", cursive;
    font-size: 50px;
    color: rgb(39, 39, 74);
}

p {
}

.page-number {
    margin-top: 10px;
    font-size: 14px;
}

h2 {
    color: #000000;
    font-weight: 600;
    font-size: 3.2rem;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.video-container {
    position: relative;
    transition: all 0.3s ease;
}

.video-container.minimized {
    width: 10%;
    height: 20vh;
    position: fixed;
    top: 15%;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #ccc;
    border-radius: 5px;
}

.video-container.maximized {
    position: fixed;
    top: 15%;
    right: 20px;
    width: 20%;
    height: 30vh;
    z-index: 1000;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.toggle-size-button {
    background: none;
    border: none;
    color: white;
}

.close-video-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
    z-index: 1000;
}

.story5-content,
.story4-content,
.story3-content,
.story2-content,
.story1-content {
    position: relative;
    font-size: 1.8vw;
    font-weight: 400;
    text-align: center;
    height: 60vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0% 10%;
}

.S1page1 .S1page2,
.S1page3,
.S1page4,
.S1page5,
.S1page6,
.S1page7,
.S1page8 {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flip-book {
    position: relative;
}

.flip-book .pageflip {
    display: block;
    position: absolute;
    z-index: 1;
    width: 0;
    height: 0;
    top: 0;
    right: 0;
    background-image: linear-gradient(to bottom left, #aaa 50%, #ffffff 51%);
    transition: all 0.5s;
}

.flip-book:hover .pageflip {
    width: 50px;
    height: 50px;
    box-shadow: -5px -5px 40px rgba(0, 0, 0, 0.367);
}

.S1page1::before {
    content: "";
    background-image: url("../public/pandapg1.png");
    background-position: left;
    background-repeat: no-repeat;
    background-size: 27vw;
    position: absolute;
    top: 0;
    left: 8%;
    width: 100%;
    height: 100%;
    opacity: 0.4;
}

.S1page2::before {
    content: "";
    background-image: url("../public/pandapg2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 35vw;
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    opacity: 0.4;
}

.S1page3::before {
    content: "";
    background-image: url("../public/pandapg3.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: 26vw;
    position: absolute;
    top: 0;
    right: 8%;
    width: 100%;
    height: 100%;
    opacity: 0.4;
}

.S1page4::before {
    content: "";
    background-image: url("../public/pandapg4.png");
    background-position: left;
    background-repeat: no-repeat;
    background-size: 16vw;
    position: absolute;
    top: 0;
    left: 8%;
    width: 100%;
    height: 100%;
    opacity: 0.4;
}

.S1page5::before {
    content: "";
    background-image: url("../public/pandapg5.png");
    background-position: left;
    background-repeat: no-repeat;
    background-size: 20vw;
    position: absolute;
    top: 0;
    left: 8%;
    width: 100%;
    height: 100%;
    opacity: 0.4;
}

.S1page6::before {
    content: "";
    background-image: url("../public/pandapg6.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 22vw;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
}

.S1page7::before {
    content: "";
    background-image: url("../public/pandapg7.png");
    background-position: left;
    background-repeat: no-repeat;
    background-size: 25vw;
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    opacity: 0.4;
}

.S1page8::before {
    content: "";
    background-image: url("../public/pandapg8.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: 30vw;
    position: absolute;
    top: 0;
    right: 8%;
    width: 100%;
    height: 100%;
    opacity: 0.4;
}
