
.homeMianBtm {
    /* position: absolute; */
    height: auto;
    width: 100%;
    margin-top: 5%;
    background-color: black;
}
.logo {
    height: 20vh;
}
.logoCont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.logoCont > img {
    height: 12vh;
}

.logoCont > h1 {
    margin-top: 3%;
}
.btmRtgtext-btn {
    display: flex;
    justify-content: center;
    gap: 20%;
    padding: 4%;
}

.company {
    width: 20%;
    height: 20vh;
    color: white;
}
.company,
.policies,
.help {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 20%;
    color: white;
}
.help {
    width: 20%;
    color: white;
}
.about,
.blog,
.contact {
    text-decoration: none;
    list-style: none;
    color: rgb(255, 255, 255);
}

.userscount {
    border: 1px solid white;
    width: 15%;
}
@media screen and (max-width: 767px) {
    .btmRtgtext-btn {
        gap: 57px;
    }
}
