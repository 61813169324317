/* .bottom {
    padding: 2%;
} */

.container {
    width: 100%;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 30px;
    /* border: 1px solid #396afc; */
    background: #212121;
    box-shadow: 15px 15px 30px rgb(25, 25, 25), -15px -15px 30px rgb(60, 60, 60);
}

.container form .descr {
    font-size: 20px;
    font-weight: 500;
    color: #e8e8e8;
    margin-bottom: 25px;
    margin-top: 25px;
    text-align: center;
}

.form {
    display: flex;
    flex-direction: column;
}

.input,
textarea {
    margin: 1em 0 1em 0;
    width: 300px;
    position: relative;
}

.input input,
textarea {
    font-size: 100%;
    padding: 0.7em;
    outline: none;
    color: #e8e8e8;
    border: none;
    border-bottom: 2px solid #e8e8e8;
    background: transparent;
    border-radius: none;
    width: 100%;
    resize: none;
}

.input label {
    font-size: 100%;
    position: absolute;
    left: 0;
    color: #e8e8e8;
    padding: 0.7em;
    margin-left: 0.1em;
    pointer-events: none;
    transition: all 0.5s ease;
    text-transform: uppercase;
}

.input :is(input:focus, input:valid) ~ label {
    transform: translateY(-50%) scale(0.9);
    margin: 0em;
    padding: 0.4em;
    background: transparent;
}

.input textarea:focus ~ label,
.input textarea:valid ~ label {
    transform: translateY(-50%) scale(0.9);
    margin: 0em;
    padding: 0.4em;
    background: transparent;
}

.inputGroup :is(input:focus, input:valid) {
    border-color: rgb(37, 37, 211);
}

.form button {
    color: #e8e8e8;
    font-size: 15px;
    align-self: flex-start;
    padding: 0.6em;
    border: none;
    cursor: pointer;
    margin-bottom: 50px;
    background: transparent;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
}

.form button:before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #e8e8e8;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.form button:hover:before {
    opacity: 0.2;
}

.form button:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 767px) {
    .container {
        position: relative;
        width: 100%;
        height: 100vh;
        background-color: #212121;
        overflow: hidden;
    }

    /* Separate background layer */
    .container::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("../../../public/Home-topimg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        filter: blur(20px); /* Apply the blur effect */
        z-index: 1; /* Keep it behind the content */
    }

    /* Content inside the container */
    .container-content {
        position: relative;
        z-index: 2; /* Ensure content is above the blurred background */
        color: white; /* Make text visible */
        padding: 20px;
    }
}
