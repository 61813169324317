/* AudioControl.css */

/* The switch - the box around the speaker */
.toggleSwitch {
    width: 50px;
    height: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:rgb(51, 51, 51);
    border-radius: 50%;
    cursor: pointer;
    transition-duration: 0.3s;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.13);
    overflow: hidden;
}

/* Hide default HTML checkbox */
#checkboxInput {
    display: none;
}

.speaker {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition-duration: 0.3s;
}

.speaker svg {
    width: 18px;
}

.mute-speaker {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    z-index: 3;
    transition-duration: 0.3s;
}

.mute-speaker svg {
    width: 18px;
}

#checkboxInput:checked + .toggleSwitch .speaker {
    opacity: 0;
}

#checkboxInput:checked + .toggleSwitch .mute-speaker {
    opacity: 1;
    background-color: red; /* Change background color when muted */
}

#checkboxInput:active + .toggleSwitch {
    transform: scale(0.7);
}

#checkboxInput:hover + .toggleSwitch {
    background-color: rgb(61, 61, 61); /* Darker background on hover */
}
